import { apiEnv } from '@/util/fieldlabApi/env'
import refreshAccessToken from '@/util/fieldlabApi/refreshAccessToken'
import axios from 'axios'
import NextAuth from 'next-auth'

// For more information on each option (and a full list of options) go to
// https://next-auth.js.org/configuration/options
export const NextAuthOptions = {
  // https://next-auth.js.org/configuration/providers
  providers: [
    {
      id: 'fieldlab',
      name: 'FieldLab',
      type: 'oauth',
      version: '2.0',
      checks: [],
      headers: {
        Accept: 'application/json',
      },
      authorization: {
        url: `${apiEnv.baseUrl}/oauth/authorize`,
        params: {
          scope: 'admin.user.registration,admin.service_labs.read',
        },
      },
      token: {
        url: `${apiEnv.baseUrl}/oauth/token`,
      },
      userinfo: {
        request: (opts) => {
          return axios.get(`${apiEnv.apiUrl}/me`, {
            headers: {
              Accept: 'application/vnd.ralstonfieldlab.json; version=1',
              Authorization: `Bearer ${opts.tokens.access_token}`,
            },
          })
        },
      },

      clientId: apiEnv.clientId,
      clientSecret: apiEnv.clientSecret,
      client: {
        token_endpoint_auth_method: 'client_secret_post',
      },
      profile(profile) {
        const primaryAccount = profile.data.data.properties.find(
          (p) => p.type === 'account'
        )

        return {
          id: profile.data.data.id,
          name: `${profile.data.data.first_name} ${profile.data.data.last_name}`,
          first_name: profile.data.data.first_name,
          last_name: profile.data.data.last_name,
          email: profile.data.data.email,
          image: null,
          is_fieldlab_admin: profile.data.data.is_fieldlab_admin,
          has_ralston_auth_session: profile.data.data.has_ralston_auth_session,
          primary_account_id: primaryAccount ? primaryAccount.data.id : null,
        }
      },
    },
  ],
  // Database optional. MySQL, Maria DB, Postgres and MongoDB are supported.
  // https://next-auth.js.org/configuration/databases
  //
  // Notes:
  // * You must install an appropriate node_module for your database
  // * The Email provider requires a database (OAuth providers do not)
  // database: process.env.DATABASE_URL,

  // The secret should be set to a reasonably long random string.
  // It is used to sign cookies and to sign and encrypt JSON Web Tokens, unless
  // a separate secret is defined explicitly for encrypting the JWT.
  secret: process.env.SECRET,

  session: {
    // Use JSON Web Tokens for session instead of database sessions.
    strategy: 'jwt',

    // Seconds - How long until an idle session expires and is no longer valid.
    maxAge: 30 * 24 * 60 * 60, // 30 days

    // Seconds - Throttle how frequently to write to database to extend a session.
    // Use it to limit write operations. Set to 0 to always update the database.
    // Note: This option is ignored if using JSON Web Tokens
    // updateAge: 24 * 60 * 60, // 24 hours
  },

  // JSON Web tokens are only used for sessions if the `jwt: true` session
  // option is set - or by default if no database is specified.
  // https://next-auth.js.org/configuration/options#jwt
  jwt: {
    // A secret to use for key generation (you should set this explicitly)
    // secret: 'INp8IvdIyeMcoGAgFGoA61DdBglwwSqnXJZkgz8PSnw',
    // Set to true to use encryption (default: false)
    // encryption: true,
    // You can define your own encode/decode functions for signing and encryption
    // if you want to override the default behaviour.
    // encode: async ({ secret, token, maxAge }) => {},
    // decode: async ({ secret, token, maxAge }) => {},
  },

  // You can define custom pages to override the built-in ones. These will be regular Next.js pages
  // so ensure that they are placed outside of the '/api' folder, e.g. signIn: '/auth/mycustom-signin'
  // The routes shown here are the default URLs that will be used when a custom
  // pages is not specified for that route.
  // https://next-auth.js.org/configuration/pages
  pages: {
    signIn: '/sign-in', // Displays signin buttons
    // signOut: '/auth/signout', // Displays form with sign out button
    // error: '/auth/error', // Error code passed in query string as ?error=
    // verifyRequest: '/auth/verify-request', // Used for check email page
    // newUser: null // If set, new users will be directed here on first sign in
  },

  // Callbacks are asynchronous functions you can use to control what happens
  // when an action is performed.
  // https://next-auth.js.org/configuration/callbacks
  callbacks: {
    // async signIn(user, account, profile) { return true },
    // async redirect(url, baseUrl) { return baseUrl },
    redirect: async ({ url, baseUrl }) => {
      return url.startsWith(baseUrl)
        ? Promise.resolve(url)
        : Promise.resolve(baseUrl)
    },
    async session({ session, token }) {
      if (token) {
        return {
          ...session,
          user: token.user,
          error: token.error,
        }
      }

      return session
    },
    // async jwt(token, user, account, profile, isNewUser) { return token }
    async jwt({ token, user, account }) {
      const now = Date.now()

      if (account) {
        return {
          ...token,
          access_token: account.access_token,
          access_token_expires: account.expires_at * 1000,
          refresh_token: account.refresh_token,
          user,
        }
      }

      const refreshAt = token.access_token_expires - 60 * 30 * 1000

      // Return previous token if the access token has not expired yet
      // if (now < token.access_token_expires - 60 * 30) {
      // return token
      if (now < refreshAt) {
        return token
      }

      // Access token has expired, try to update it
      return await refreshAccessToken(token)
    },
  },

  // Events are useful for logging
  // https://next-auth.js.org/configuration/events
  events: {
    async signOut({ token }) {
      try {
        await axios({
          url: `${apiEnv.baseUrl}/oauth/token/revoke`,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token.access_token}`,
          },
          data: {
            token: token.access_token,
            client_id: apiEnv.clientId,
            client_secret: apiEnv.clientSecret,
          },
        })
      } catch (error) {
        console.error(error.message)
        if (error.response) {
          console.error(error.request.config)
        }
      }
    },
    async error(message) {
      console.error('NextAuth Error Event')
      console.error(message)
    },
  },

  // You can set the theme to 'light', 'dark' or use 'auto' to default to the
  // whatever prefers-color-scheme is set to in the browser. Default is 'auto'
  theme: 'light',

  // Enable debug messages in the console if you are having problems
  debug: false,
}

export default NextAuth(NextAuthOptions)

export const config = {
  api: {
    externalResolver: true,
  },
}
