import axios from 'axios'
import { apiEnv } from '@/util/fieldlabApi/env'

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
export default async function refreshAccessToken(token) {
  try {
    const response = await axios({
      url: `${apiEnv.apiUrl}/oauth/token`,

      headers: {
        Accept: 'application/json',
      },
      data: {
        client_id: apiEnv.clientId,
        client_secret: apiEnv.clientSecret,
        grant_type: 'refresh_token',
        refresh_token: token.refresh_token,
      },
      method: 'POST',
    })

    if (!response.data) {
      console.log('NO RESPONSE DATA')
      throw response
    }

    console.log('YES RESPONSE DATA')
    console.log(response.data)

    const created = new Date(response.data.created_at)
    return {
      ...token,
      access_token: response.data.access_token,
      access_token_expires: created.getTime() + response.data.expires_in * 1000,
      refresh_token: response.data.refresh_token ?? token.refresh_token, // Fall back to old refresh token
    }
  } catch (error) {
    // console.log('ERROR HERE')
    // console.log(error)
    // console.log('END ERROR HERE')
    console.log('RefreshAccessTokenError')
    console.log(
      JSON.stringify(
        {
          responseStatus: error.response?.status,
          client_id: apiEnv.clientId,
          client_secret: apiEnv.clientSecret,
          grant_type: 'refresh_token',
          refresh_token: token.refresh_token,
        },
        null,
        2
      )
    )

    return {
      ...token,
      error: 'RefreshAccessTokenError',
    }
  }
}
